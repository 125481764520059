import * as React from "react";
import Roster from "./Roster";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { MONTHS, isSaturday, isSunOrHoliday, toMicrosoftDate } from "./DateUtil";

/**
 * Creates the passed plan in the specified excel worksheet.
 */
async function createRoster(roster: Roster, ws: Excel.Worksheet) {
  // clear any existing data in draw range
  ws.getRangeByIndexes(0, 0, 35, 6).clear();

  // set column widths
  ws.getRange("A:B").format.columnWidth = 20;
  ws.getRange("C:E").format.columnWidth = 90;
  ws.getRange("F:F").format.columnWidth = 140;

  // format title range
  var titleRange: Excel.Range = ws.getRange("D2:E2");
  titleRange.merge();
  titleRange.format.font.size = 14;
  titleRange.format.horizontalAlignment = "Center";

  // write title and column headers  
  ws.getRange("D2").values = [["Dienstplan " + MONTHS[roster.month] + " " + roster.year]];
  ws.getRange("C4:F4").values = [["Vormittag", "Nachmittag", "Nacht", "Bemerkung"]];

  // set a continous border around all cells
  ws.getRangeByIndexes(4, 0, roster.getDaysInMonth(), 6).format.borders.getItem("EdgeTop").style = "Continuous";
  ws.getRangeByIndexes(4, 0, roster.getDaysInMonth(), 6).format.borders.getItem("EdgeBottom").style = "Continuous";
  ws.getRangeByIndexes(4, 0, roster.getDaysInMonth(), 6).format.borders.getItem("EdgeLeft").style = "Continuous";
  ws.getRangeByIndexes(4, 0, roster.getDaysInMonth(), 6).format.borders.getItem("EdgeRight").style = "Continuous";
  ws.getRangeByIndexes(4, 0, roster.getDaysInMonth(), 6).format.borders.getItem("InsideHorizontal").style = "Continuous";
  ws.getRangeByIndexes(4, 0, roster.getDaysInMonth(), 6).format.borders.getItem("InsideVertical").style = "Continuous";

  // set the font size of the comment column
  ws.getRangeByIndexes(4, 5, roster.getDaysInMonth(), 1).format.font.size = 8;

  // create individual days
  for (let i = 0; i < roster.getDaysInMonth(); i++) {
    let date = roster.columns["date"][i];

    // set the date format of the first two columns
    ws.getRangeByIndexes(i + 4, 0, 1, 2).numberFormat = [["d", "ddd"]];

    // write dates to the first two columns and a comment in the sixth column    
    let msdate = toMicrosoftDate(date);
    ws.getRangeByIndexes(i + 4, 0, 1, 6).values = [[msdate, msdate, null, null, null, roster.columns["comment"][i]]];

    if (isSunOrHoliday(date)) {
      // format foreground of the second cell in the current days' row
      ws.getRangeByIndexes(i + 4, 1, 1, 1).format.font.color = "#FF4D00";

      // merge morning, afternoon and night cells and change alignment to center
      let range = ws.getRangeByIndexes(i + 4, 2, 1, 3);
      range.merge();
      range.format.horizontalAlignment = "Center";

      // fill background with solid color
      ws.getRangeByIndexes(i + 4, 0, 1, 6).format.fill.set({
        color: "#FFAF3B",
        pattern: "Solid",
      });

    } else if (isSaturday(date)) {
      // merge afternoon and night cells and change alignment to center
      let range = ws.getRangeByIndexes(i + 4, 3, 1, 2);
      range.merge();
      range.format.horizontalAlignment = "Center";
    }
  }
}

export interface AppState {
  month: number,
  year: number
}

export default class App extends React.Component<{}, AppState> {
  currentDate: Date;

  constructor(props, context) {
    super(props, context);

    // time of first app start is stored and used whenever the current date is needed somewhere
    this.currentDate = new Date();

    this.state = {
      month: (this.currentDate.getMonth() + 1) % 12,
      year: this.currentDate.getMonth() == 11 ? this.currentDate.getFullYear() + 1 : this.currentDate.getFullYear()
    };

    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * Gets the available options that can be selected in the 'select month' dropdown.
   */
  getMonthOptions(): IDropdownOption[] {
    var options: IDropdownOption[] = []

    MONTHS.forEach((month, i) => {
      options.push({ key: i, text: month });
    });

    return options;
  }

  /**
   * Gets the available options that can be selected in the 'select year' dropdown.
   */
  getYearOptions(): IDropdownOption[] {
    var currentYear = this.currentDate.getFullYear();

    var options: IDropdownOption[] = []
    for (let i = currentYear; i < currentYear + 10; i++) {
      options.push({ key: i, text: i.toString() });
    }

    return options;
  }

  handleMonthChange(_, item) {
    this.setState({ month: item.key });
  }

  handleYearChange(_, item) {
    this.setState({ year: item.key });
  }

  handleClick = async () => {
    var roster = new Roster(this.state.year, this.state.month);

    try {
      await Excel.run(async context => {
        var ws = context.workbook.worksheets.getActiveWorksheet();

        await createRoster(roster, ws);
        await context.sync();
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <div id="appwrapper">
        <div className="wrapper">
          <section className="dp-header">
            <img width="90" height="90" src="assets/logo-circle.png" />
            <h1 className="ms-fontWeight-light">Dienstplan</h1>
            <h2 className="ms-fontWeight-light">Version 2.0</h2>
          </section>
          <section>
            <ul className="ms-List ms-u-slideUpIn10 herolist">
              <li className="ms-ListItem">
                <div className="caption">
                  <i className="ms-Icon ms-Icon--DoubleChevronRight"></i>
                  <span className="ms-font-l ms-fontColor-neutralPrimary">Monat auswählen</span>
                </div>
                <Dropdown placeholder="Monat auswählen" options={this.getMonthOptions()} defaultSelectedKey={this.state.month} onChange={this.handleMonthChange}></Dropdown>
              </li>
              <li className="ms-ListItem">
                <div className="caption">
                  <i className="ms-Icon ms-Icon--DoubleChevronRight"></i>
                  <span className="ms-font-l ms-fontColor-neutralPrimary">Jahr auswählen</span>
                </div>
                <Dropdown placeholder="Jahr auswählen" options={this.getYearOptions()} defaultSelectedKey={this.state.year} onChange={this.handleYearChange}></Dropdown>
              </li> <li className="ms-ListItem">
                <div className="caption">
                  <i className="ms-Icon ms-Icon--DoubleChevronRight"></i>
                  <span className="ms-font-l ms-fontColor-neutralPrimary">Erstellen</span>
                </div>
                <PrimaryButton text="Erstellen" onClick={this.handleClick} />
              </li>
            </ul>
          </section>
          <div className="push"></div>
        </div>
        <footer>
          <TooltipHost content="Geburtstagsgeschenk zum 55er für Papa">
            <i className="ms-Icon ms-Icon--Info"></i>
          </TooltipHost>
        </footer>
      </div>
    );
  }
}
