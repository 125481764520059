const fjs = require("feiertagejs");
const moment = require("moment-msdate");

// region Bavaria is fixed for holiday determination
const region = "BY";

const MONTHS = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

function isSaturday(date: Date) : boolean {
    return date.getDay() == 6;
}

function isSunday(date: Date) : boolean {
    return date.getDay() == 0;
}

function isHoliday(date: Date) : boolean {
    return fjs.isHoliday(date, region);
}

function isWeekendOrHoliday(date: Date) : boolean {
    return  isSaturday(date) || isSunOrHoliday(date);
}

function isSunOrHoliday(date: Date) : boolean {
    return isSunday(date) || isHoliday(date);
}

function getHolidayName(date: Date) : string {
    return fjs.getHolidayByDate(date, region).trans();
}

function toMicrosoftDate(date: Date) {
    // create a UTC date object showing the same year, month and day
    var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    
    // convert to moment
    var momentDate = moment(utcDate);

    // convert to MS date
    var msdate = momentDate.toOADate();
    return msdate;
}

export { MONTHS, isSaturday, isSunday, isHoliday, isSunOrHoliday, isWeekendOrHoliday, getHolidayName, toMicrosoftDate};