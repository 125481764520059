import { isHoliday, getHolidayName } from "./DateUtil";

class Roster {
    year: number;
    month: number;
    columns: object;

    constructor(year: number, month: number) {
        this.year = year;
        this.month = month;        
        this.columns = {
            date: [],
            morning: [],
            afternoon: [],
            night: [],
            comment: []
        };

        for (let i = 0; i < this.getDaysInMonth(); i++) {
            var date = new Date(year, month, i + 1);

            this.columns["date"][i] = date;
            this.columns["morning"][i] = [];
            this.columns["afternoon"][i] = [];
            this.columns["night"][i] = [];

            if (isHoliday(date)) {                
                this.columns["comment"][i] = getHolidayName(date);
            } else {
                this.columns["comment"][i] = "";
            }
        }
    }

    getDaysInMonth() : number {
        // month is 1-based here (javascript fuck-up)
        return new Date(this.year, this.month + 1, 0).getDate();
    }

    // getWeekendDayOrHolidayCount() : number {
    //     let count = 0;
    //     this.columns["date"].forEach(date => {
    //         if (isWeekendOrHoliday(date)) {
    //             count++;
    //         }
    //     });

    //     return count;
    // }    

    // getWorkingDayCount() : number  {
    //     return this.getDaysInMonth() - getWeekendDayOrHolidayCount();
    // }

    print() {
        let rows = [];

        for (let i = 0; i < this.columns["date"].length; ++i) {
            rows.push({
                date: this.columns["date"][i],
                morning: this.columns["morning"][i],
                afternoon: this.columns["afternoon"][i],
                night: this.columns["night"][i],
                comment: this.columns["comment"][i]
            });
        }

        console.table(rows);
    }
}

export default Roster;